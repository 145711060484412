<template>
  <div class="py-7 container-fluid">
    <div class="card w-70">
      <div class="card-header pb-0">
        <h6>Add new Schedule Snapshot</h6>
      </div>
      <div class="card-body px-5 pt-5 pb-2">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Project</label
          >
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="schedule.stack"
          >
            <option disabled>Select project</option>
            <option v-for="(s, index) in uniqueStacks" :key="index" :value="s">
              {{ s.namespace.name }}
            </option>
          </select>
        </div>
        <div class="mb-3" v-if="schedule.stack !== null">
          <label for="exampleFormControlInput1" class="form-label"
            >Stacks</label
          >
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="selectedStack"
          >
            <option disabled>Select stack</option>
            <option
              v-for="(s, index) in filteredStacks"
              :key="index"
              :value="s"
            >
              {{ s.name }}
            </option>
            <option v-if="filteredStacks.length === 0" disabled>
              Not found stacks in namespace {{ schedule.stack.namespace.name }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label for="retainDays" class="form-label">Retain days</label>
          <input
            type="number"
            class="form-control"
            id="retainDays"
            v-model="schedule.retainDays"
            value="schedule.retainDays"
            :style="{ width: inputWidth }"
            placeholder="5"
          />
        </div>

        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">Cron</label>
          <input
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            v-model="schedule.cron"
            value="schedule.cron"
            :style="{ width: inputWidth }"
            placeholder="* * * * * *"
          />
        </div>
        <div class="mb-3">
          <button @click="addSchedule()" type="button" class="btn btn-primary">
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

const toast = useToast();
export default {
  components: {},
  data() {
    return {
      schedule: {
        stack: null,
        cron: "",
        active: true,
        retainDays: 5,
        namespace: null,
        selectedStack: null,
      },
      stacks: [],
    };
  },
  computed: {
    uniqueStacks() {
      return this.stacks.filter(
        (stack, index, self) =>
          index ===
          self.findIndex((s) => s.namespace.name === stack.namespace.name)
      );
    },
    filteredStacks() {
      if (!this.schedule.stack) {
        return [];
      }
      return this.stacks.filter(
        (s) => s.namespace.name === this.schedule.stack.namespace.name
      );
    },
  },
  mounted() {
    this.loadStacks();
  },
  methods: {
    loadStacks() {
      this.$api
        .get(`/v1/stack?nopaginate=1`)
        .then((response) => {
          this.stacks = response.data.stacks;
        })
        .catch((error) => {
          console.error("Error fetching stack:", error);
        });
    },
    async addSchedule() {
      try {
        const response = await this.$api.post(
          "/v1/schedule/snapshot",
          this.schedule
        );
        if (response.status >= 200 && response.status < 300) {
          toast.success("Schedule snapshot create successful");
          this.$router.push({ name: "ScheduleSnapshot" });
        }
      } catch (error) {
        toast.error("Schedule snapshot create error");
      }
    },
  },
};
</script>
